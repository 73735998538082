* {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    font-family: "DM Sans", sans-serif;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../../public/fonts/DMSans-Bold.ttf');
    src: local('DM Sans Bold'), local('DMSans-Bold'), url('../../public/fonts/DMSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../../public/fonts/DMSans-Bold.ttf');
    src: local('DM Sans Bold'), local('DMSans-Bold'), url('../../public/fonts/DMSans-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../../public/fonts/DMSans-Medium.ttf');
    src: local('DM Sans Medium'), local('DMSans-Medium'), url('../../public/fonts/DMSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../../public/fonts/DMSans-Regular.ttf');
    src: local('DM Sans Regular'), local('DMSans-Regular'), url('../../public/fonts/DMSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
.call-to-action {
    background-image: url("../../public/images/compressed/homepage/homepage-cta.webp");
    height: 495px;
    width: 100%;
    background-size: cover;
    object-fit: cover;
}

.call-to-action.visible {
    background-image: url("../../public/images/homepage/action.png");
}

.gradients {
    background-image: linear-gradient(0deg, rgba(18, 32, 63, 0.03), rgba(18, 32, 63, 0.03));
}

@media screen and (min-width: 769px) and (max-width: 1280px) {
    .call-to-action {
        height: 352px;
        background-image: url("../../public/images/homepage/tablet-action.png");
        background-size: cover;
        object-fit: cover;
    }
}

@media screen and (max-width: 768px) {
    .call-to-action {
        height: 300px;
        background-image: url("../../public/images/homepage/mobile-action.png");
        object-fit: cover;
        background-size: cover;
        background-position: center;
    }
}

.swiper-slide-next,
.swiper-slide-prev {
    width: 100px;
}

@media screen and (min-width: 769px) and (max-width: 1280px) {
  #swiperImageProjectDetailCatalouge .swiper-wrapper {
    translate: 10.2% 0px
  }
}
